import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PrimaryButton from "_components/buttons/button_primary"
import Spinner from "_components/global/spinner"
import styled from "styled-components"

const ConnectionModal = (props) => {
  return (
    <>
      <Background />
      <Modal>
        <div style={{ width: "90vw", maxWidth: props.maxWidth }}>
          <Title>{props.title}</Title>
          <Copy>{props.p2}</Copy>
          {props.showImage && (
            <ImgWrapper>
              <StaticImage src="ConnectToVideo.png" alt="Arrange windows" />
            </ImgWrapper>
          )}
          <Copy>{props.p3}</Copy>
          <Copy>{props.p4}</Copy>

          {props.buttonText && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "10px 0 10px 0",
              }}
            >
              <ButtonWrapper
                onClick={() => {
                  props.set_buttonPress(true)
                  if (props.windowOpenTo != null) {
                    window.open(
                      props.windowOpenTo,
                      "activeEMDR_clientWindow",
                      "height=400,width=700,status=no, toolbar=no,menubar=no,location=no"
                    )
                  }
                }}
              >
                <PrimaryButton buttonText={props.buttonText} />
              </ButtonWrapper>
            </div>
          )}

          <InstructionsWrapper>
            <div style={{ width: "30px" }}>
              <Spinner />
            </div>
            <Instructions>{props.instructions}</Instructions>
          </InstructionsWrapper>
        </div>
      </Modal>
    </>
  )
}

const Background = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.9;
`

const Modal = styled.div`
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 15px 0;
`

const Title = styled.div`
  font-family: montserrat;
  font-size: 23px;
  font-weight: 1000;
  line-height: 28px;
  color: #aa58c5;
  padding-bottom: 10px;
  @media only screen and (max-width: 400px) {
    font-size: 16px;
    line-height: 21px;
    font-weight: 800;
    padding-bottom: 10px;
  }
`

const Copy = styled.div`
  font-family: montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  color: #1a4e8e;
  padding-bottom: 10px;
  @media only screen and (max-width: 400px) {
    font-size: 13px;
    line-height: 18px;
    padding-bottom: 2px;
  }
`

const ButtonWrapper = styled.div`
  width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InstructionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
`

const Instructions = styled.div`
  font-family: montserrat;
  font-size: 14px;
  text-align: center;
  font-weight: 800;
  line-height: 18px;
  color: #20497b;
  @media only screen and (max-width: 400px) {
    font-size: 13px;
    line-height: 16px;
  }
`

export default ConnectionModal
