import { useEffect, useContext } from "react"
import { Logger } from "_data/global_functions.js"
import { SocketContext } from "_app/common/SocketServer"
import { ClientContext } from "_app/client/ClientGlobalVars"

const ClientSocket = (props) => {
  const socket = useContext(SocketContext)
  const g = useContext(ClientContext)

  // :s: Check for server availability.
  useEffect(() => {
    if (socket) {
      g.set_servAvailable(socket.connected)
    }
  }, [g, socket])

  // :s: Recognize server connects and disconnects.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("connect", () => {
        if (!didCancel) g.set_servAvailable(true)
      })

      socket.on("disconnect", function () {
        if (!didCancel) g.set_servAvailable(false)
      })

      socket.on("userDisconnect", () => {
        if (!didCancel) g.set_theraConnected(false)
      })

      socket.on("endSession", function () {
        if (!didCancel) {
          g.set_theraConnected(false)
          props.set_sessionEnded(true)
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g, props])

  // :s: Server acks connection requests.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("serverAck", (data) => {
        if (!didCancel) {
          if (data.to === "therapist") {
            Logger("Therapist has joined session on: " + data.sessionName)
            g.set_theraConnected(true)
            socket.emit("clientReady", {
              sessionName: data.sessionName,
              //   from: "client",
            })
          }
          if (data.to === "client") {
            g.set_sessionName(data.sessionName)
            g.set_servConnected(true)
          }
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [g, socket])

  // :s: Reconnect client to server when server goes from unavailable to available.
  // --> Added from old Socket on 3/8
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("connect", (data) => {
        if (!didCancel) {
          if (g.servConnected) {
            socket.emit("join", {
              sessionName: g.sessionName,
              from: "client",
              // to: "server",
            })
          }
        }
        g.set_servAvailable(true)
      })

      return () => {
        socket.off("connect")
        didCancel = true
      }
    }
  }, [socket, g])

  // :s: Received noVideo socket from therapist.
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("noVideo", () => {
        if (!didCancel) {
          g.set_thera_clientUrl("noVideo")
          g.set_thera_providerChosen(false)
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g])

  // :s: Watch for video settings request
  useEffect(() => {
    let didCancel = false

    if (socket) {
      socket.on("videoSettings", (data) => {
        Logger("Received videoSettings.  clientUrl: " + data.clientUrl)
        if (!didCancel) {
          g.set_theraConnected(true) // set flag if first we hear from therapist.

          if (data.clientUrl === "noVideo") {
            g.set_integratedProvider(false)
            g.set_thera_vidWinOpen(false)
            g.set_thera_clientUrl("noVideo")
            g.set_vidWinOpen(false)
          } else {
            if (data.integratedProvider === "true")
              g.set_integratedProvider(true)
            else g.set_integratedProvider(false)

            // if (data.clientUrl === "wait") g.set_thera_vidWinOpen(false)
            // else
            g.set_thera_vidWinOpen(true)
            g.set_vidWinOpen(false) // Client video window has not opened yet.
            g.set_thera_clientUrl(data.clientUrl) // url that therapist wants client to use.
          }
        }
      })

      return () => {
        didCancel = true
      }
    }
  }, [socket, g])

  return null
}

export default ClientSocket
