import React, { createContext, useState } from "react"

export const ClientContext = createContext()

// servAvailable
// servConnected
// vidWinOpen
// reconnAfterDisc
// theraConnected
// thera_providerChosen
// thera_vidWinOpen
// thera_clientUrl

export const UserProvider = ({ children }) => {
  const [sessionName, set_sessionName] = useState("")
  const [sessEstab, set_sessEstab] = useState(false)
  const [vidReady, set_vidReady] = useState(false)
  const [clientUrl, set_clientUrl] = useState("")
  const [showVideo, set_showVideo] = useState(false)
  const [vidIsOpen, set_vidIsOpen] = useState(false)

  const [servAvailable, set_servAvailable] = useState(false)
  const [servConnected, set_servConnected] = useState(false)
  const [vidWinOpen, set_vidWinOpen] = useState(false)
  const [reconnAfterDisc, set_reconnAfterDisc] = useState(false)
  const [theraConnected, set_theraConnected] = useState(false)
  const [thera_providerChosen, set_thera_providerChosen] = useState(false)
  const [thera_vidWinOpen, set_thera_vidWinOpen] = useState(false)
  const [thera_clientUrl, set_thera_clientUrl] = useState(false)
  const [integratedProvider, set_integratedProvider] = useState(false)

  return (
    <ClientContext.Provider
      value={{
        sessionName,
        set_sessionName,
        sessEstab,
        set_sessEstab,
        vidReady,
        set_vidReady,
        clientUrl,
        set_clientUrl,
        showVideo,
        set_showVideo,
        vidIsOpen,
        set_vidIsOpen,
        servAvailable,
        set_servAvailable,
        servConnected,
        set_servConnected,
        vidWinOpen,
        set_vidWinOpen,
        reconnAfterDisc,
        set_reconnAfterDisc,
        theraConnected,
        set_theraConnected,
        thera_providerChosen,
        set_thera_providerChosen,
        thera_vidWinOpen,
        set_thera_vidWinOpen,
        thera_clientUrl,
        set_thera_clientUrl,
        integratedProvider,
        set_integratedProvider
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}
