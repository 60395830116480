import React from "react"
import { color_primButton } from "_src/assets/data/global_colors"
import { motion } from "framer-motion"
import styled from "styled-components"

/*
    Last modified: 02/05/20
    :: This component was created with the intention of being used in
    :: multiple places throughout the site, but right now is limited to
    :: the connectionAlert.
*/

const ButtonTest = (props) => {
  return (
    <Wrapper>
      <Button
        style={{
          perspective: "500px",
          backgroundColor: color_primButton,
        }}
        initial={{ rotate: 0.01, scale: 1.01 }}
        transition={{
          type: "spring",
          stiffness: 530,
          damping: 60,
          mass: 6,
          restDelta: 0.001,
          restSpeed: 0.001,
        }}
        whileHover={{
          scale: 1.05,
          rotate: 0.02,
          backgroundColor: "#984FB8",
        }}
        whileTap={{ scale: 0.99, buttonStatus: 1 }}
      >
        {props.buttonText}
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = styled(motion.button)`
  font-family: montserrat;
  font-size: 12px;
  cursor: pointer;
  font-weight: 550;
  color: white;
  border: 0;
  border-radius: 8px;
  text-align: center;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.4), 12px 12px 25px rgba(0, 0, 0, 0.24);
  border: none;
  outline: none;
  padding: 15px 20px 15px 20px;
`

export default ButtonTest
