import React, { useState, useContext, useEffect } from "react"
import { FormInput } from "_data/global_styles"
import { HeaderContainer } from "_data/global_styles"
import { Logger, NewCookieExpiryDate } from "_data/global_functions.js"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import Footer from "_components/nav/footer/_footer"
import Header from "_components/nav/header/_header"
import PageHeadline from "_components/misc/pageHeadline"
import ConnectButton from "_app/common/ConnectButton"
import { SocketContext } from "_app/common/SocketServer"
import styled from "styled-components"
import { Highlight, Screen, Content, Form, Row } from "_app/common/styles"
import Spinner from "_components/global/spinner"
import { ClientContext } from "_app/client/ClientGlobalVars"
import queryString from "query-string"

const InitiateClientSession = ({ location }) => {
  const [cookie, set_cookie] = useCookies(["sessionName_client"])
  const [buttonText, set_buttonText] = useState("Join Therapist")
  const [showMessage, set_showMessage] = useState(false)
  const socket = useContext(SocketContext)
  const g = useContext(ClientContext)
  const [parsedUrl, set_parsedUrl] = useState("")

  const { register, handleSubmit } = useForm({
    defaultValues: {
      sessionName: cookie.sessionName_client,
    },
  })

  useEffect(() => {
    Logger("theraconnected: " + g.theraConnected)
  }, [g.theraConnected])

  // :s: React to button click to join session
  const StartRemoteSession_onClick = (data) => {
    set_showMessage(true)
    set_buttonText("Waiting...")
    g.set_sessionName(data.sessionName)
    set_cookie("sessionName_client", data.sessionName, {
      path: "/",
      expires: NewCookieExpiryDate(360),
    })
    socket.emit("join", {
      sessionName: data.sessionName,
      from: "client",
    })
  }

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)
    if (parsed.session) {
      Logger("we have url session: " + parsed.session)
      set_parsedUrl(parsed.session)
    } else Logger("We do not have session")
  }, [])

  useEffect(() => {
    if (parsedUrl !== "") {
      set_showMessage(true)
      set_buttonText("Waiting...")
      g.set_sessionName(parsedUrl)
      set_cookie("sessionName_client", parsedUrl, {
        path: "/",
        expires: NewCookieExpiryDate(360),
      })
      socket.emit("join", {
        sessionName: parsedUrl,
        from: "client",
      })
    }
  }, [g, socket, set_cookie, parsedUrl])

  // ->
  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            {!g.servAvailable && (
              <PageHeadline copy="Starting the communications server." />
            )}

            {g.servAvailable && parsedUrl === "" && (
              <PageHeadline copy="Join your therapist for an online session." />
            )}

            {g.servAvailable && parsedUrl !== "" && (
              <PageHeadline copy="Your session will start soon." />
            )}

            <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
              {!g.servAvailable && (
                <>
                  <Paragraph>
                    We are starting the communication server. This can take up
                    to 30 seconds. Please be patient and do not leave this page, it will refresh automatically.{" "}
                    <Highlight>
                      Did you arrive at this page by using your browsers back or
                      forward button?
                    </Highlight>{" "}
                    If so, reload this page now.
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                    <Message>Starting communications server...</Message>
                  </div>
                </>
              )}
              {g.servAvailable && parsedUrl === "" && (
                <Paragraph>
                  Please enter the <Highlight>Session Password</Highlight> that
                  your therapist has provided you with. It is important that it
                  matches exactly as given.
                </Paragraph>
              )}
            </div>

            {g.servAvailable && parsedUrl === "" && (
              <Form onSubmit={handleSubmit(StartRemoteSession_onClick)}>
                <Row>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    <FormInput
                      name="sessionName"
                      type="text"
                      ref={register}
                      required
                    />
                  </div>
                  <ConnectButton buttonText={buttonText} />
                </Row>
              </Form>
            )}
            {g.servAvailable && g.servConnected && !g.theraConnected && (
              <MessageWrapper
                style={{
                  opacity: showMessage ? 1 : 0,
                  height: "70px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                  <Message>
                    Patiently waiting for your therapist to join...
                  </Message>
                </div>

                <Warning>(Please do not leave this page)</Warning>
              </MessageWrapper>
            )}
          </motion.div>
        </Content>
      </Screen>
      <Footer />
    </>
  )
}

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Message = styled(motion.div)`
  font-family: montserrat;
  font-size: 15px;
  font-weight: 600;
  color: #303c6c;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
    font-weight: 800;
  }
`

const Warning = styled.div`
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  color: #303c6c;
  text-align: center;
`

export default InitiateClientSession
