import React, { useState, useEffect, useContext } from "react"
import { SocketContext, socket } from "_app/common/SocketServer"
import InitiateSession from "_app/client/InitiateSession"
import ClientSocket from "_app/client/socket/ClientSocket"
import ConnectionModal from "_app/client/modals/ConnectToVideo"
import Dashboard from "_app/client/Dashboard"
import { ClientContext } from "_app/client/ClientGlobalVars"

const bypassServConn = false
const bypassVid = false

const Client = () => {
  const [connsMade, set_connsMade] = useState(false)
  const [sessionEnded, set_sessionEnded] = useState(false)
  const g = useContext(ClientContext)

  // :s: Debug states.
  // useEffect(() => {
  //   if (g.servAvailable != null) {
  //     console.log("-----")
  //     console.log("g.servAvailable: " + g.servAvailable)
  //     console.log("g.servConnected: " + g.servConnected)
  //     console.log("g.vidWinOpen: " + g.vidWinOpen)
  //     console.log("g.reconnAfterDisc: " + g.reconnAfterDisc)
  //     console.log("g.thera_vidWinOpen: " + g.thera_vidWinOpen)
  //     console.log("g.thera_providerChosen: " + g.thera_providerChosen)
  //     console.log("g.thera_clientUrl: " + g.thera_clientUrl)
  //     console.log("g.theraConnected: " + g.theraConnected)
  //     console.log("g.integratedProvider: " + g.integratedProvider)
  //     console.log("-----")
  //   }
  // }, [
  //   g.servAvailable,
  //   g.servConnected,
  //   g.thera_providerChosen,
  //   g.vidWinOpen,
  //   g.thera_vidWinOpen,
  //   g.reconnAfterDisc,
  //   g.thera_clientUrl,
  //   g.theraConnected,
  //   g.integratedProvider,
  // ])

  // :s: Change connsMade state after therapist and server connected
  // --> Reworked w/ new states on 2/24/21
  // --> We are keeping connsMade at this point
  useEffect(() => {
    if (g.servConnected && g.theraConnected) {
      set_connsMade(true)
    }
  }, [g.servConnected, g.theraConnected])

  return (
    <>
      <SocketContext.Provider value={socket}>
        <ClientSocket set_sessionEnded={set_sessionEnded} />
        {!connsMade && !bypassServConn && <InitiateSession />}
        {connsMade && <Dashboard sessionName={g.sessionName} />}

        {/* --> Therapist has disconnected  modified 2/24 */}
        {connsMade &&
          !g.theraConnected &&
          g.servAvailable &&
          !bypassServConn &&
          !sessionEnded && (
            <ConnectionModal
              maxWidth="500px"
              title="Your therapist has disconnected."
              p2="Your therapist has either ended the session or has lost connection.  If this was unexpected, 
              please wait while we reconnect them."
              instructions="If this was unexpected, please wait here for your therapist."
            />
          )}

        {/* --> Therapist has ended session. */}
        {sessionEnded && (
          <ConnectionModal
            maxWidth="500px"
            title="The session has ended."
            p2="If you feel that this should 
            not have happened, please contact your therapist for instructions."
            instructions="You may close this browser."
          />
        )}

        {/* --> Client lost connection to server.  modified 2/24 */}
        {connsMade && !g.servAvailable && !bypassServConn && (
          <ConnectionModal
            maxWidth="500px"
            title="You have lost connection to the session communications server."
            p2="This usually occurs when there is an unstable internet connection.  Please hang 
            tight while we try to reconnect you."
            instructions="Attempting reconnect. Do not refresh your browser."
          />
        )}

        {/* --> Confirm to launch video conferencing.  modified 2/24 */}
        {g.thera_vidWinOpen &&
          !g.integratedProvider &&
          !g.vidWinOpen &&
          g.servAvailable &&
          g.theraConnected &&
          !bypassVid &&
          g.thera_clientUrl !== "noVideo" && (
            <ConnectionModal
              maxWidth="700px"
              title="Your therapist is ready to video conference."
              p2="Click the button below to open a new browser for video conferencing.  After open,
               arrange the two so that you can see both at the same time,   
               making sure this window is as wide as possible. "
              buttonText="Open Video Window"
              showImage={true}
              windowOpenTo={g.thera_clientUrl}
              set_buttonPress={g.set_vidWinOpen}
              instructions="WARNING: You may need to disable popup blockers."
            />
          )}
      </SocketContext.Provider>
    </>
  )
}

export default Client
