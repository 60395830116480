import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import Seo from "_components/Seo/client"
import { SocketContext, socket } from "_app/common/SocketServer"
import { UserProvider } from "_app/client/ClientGlobalVars"
import ClientApp from "_app/client/Client"

// :s: Default
const Client = () => {
  return (
    <UserProvider>
      <SocketContext.Provider value={socket}>
        <Seo />
        <ClientApp />
      </SocketContext.Provider>
    </UserProvider>
  )
}

export default Client
