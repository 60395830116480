import React, { useContext } from "react"
import styled from "styled-components"
import Header from "_app/client/header/Header"
import Bls from "_app/common/bls/Bls"
import { ClientContext } from "_app/client/ClientGlobalVars"
import IntegratedVideo from "_app/common/IntegratedVideo"

const ClientDashboard = (props) => {
  const g = useContext(ClientContext)
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <BlsWrapper>
        <Bls isTherapist={false} sessionName={props.sessionName} />
      </BlsWrapper>

      {g.integratedProvider && g.thera_vidWinOpen && (
        <VideoWrapper>
          <IntegratedVideo isTherapist={false} url={g.thera_clientUrl} />
        </VideoWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 45px;
  padding-right: 20px;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.2),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.05),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.05);
`

const BlsWrapper = styled.div`
  position: relative;
  z-index: 10;
  height: 75%;
  /* background-color: grey; */
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow: hidden;
`

export default ClientDashboard
