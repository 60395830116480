import React, { useContext, useState, useEffect } from "react"
import { BsFillPeopleFill } from "react-icons/bs"
import { SiAudiomack } from "react-icons/si"
import { motion, useSpring } from "framer-motion"
import Logo from "_components/misc/logo"
import styled from "styled-components"
import { ClientContext } from "_app/client/ClientGlobalVars"
import useSound from "use-sound"
import leftSound from "../../common/bls/beepTest.mp3"

const color_connected = "#3296e5"

const ShowClientVideo = () => {
  const [showAudioTip, set_audioTip] = useState(false)
  const [leftPlay] = useSound(leftSound)
  const [showToolTip, set_showToolTip] = useState(false)
  const [toolTip, set_toolTip] = useState(
    "You are connected to your therapist."
  )
  const g = useContext(ClientContext)
  const toolTip_opacity = useSpring()

  function OnClick() {
    if (g.thera_clientUrl !== "noVideo") {
      window.open(
        g.thera_clientUrl,
        "activeEMDR_clientWindow",
        "height=400,width=700,status=no, toolbar=no,menubar=no,location=no"
      )
    }
  }

  useEffect(() => {
    if (g.vidWinOpen) {
      set_toolTip("Click to re-launch video window.")
    }
  }, [g.vidWinOpen])

  useEffect(() => {
    if (g.thera_clientUrl === "noVideo")
      set_toolTip("You are connected to your therapist.")
    else {
      set_toolTip("Click to launch video conferencing.")
    }
  }, [g.thera_clientUrl])

  // ->
  return (
    <>
      <Wrapper>
        <Row>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 3, ease: "easeOut" }}
            style={{ userSelect: "none" }}
          >
            <Logo multiplier=".65" />
          </motion.div>

          <Right>
            <motion.div
              onHoverStart={() => {
                set_showToolTip(true)
                toolTip_opacity.set(1)
              }}
              onHoverEnd={() => {
                set_showToolTip(false)
                toolTip_opacity.set(0)
              }}
            >
              <HeaderIcon
                style={{
                  color: color_connected,
                  cursor: g.thera_clientUrl !== "noVideo" ? "pointer" : "",
                }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                whileHover={{
                  color: "#225ead",
                }}
                onClick={OnClick}
              >
                <BsFillPeopleFill />
              </HeaderIcon>
              <HeaderToolTip
                style={{
                  opacity: toolTip_opacity,
                  display: !showToolTip ? "none" : "",
                  right: "0px",
                }}
              >
                {toolTip}
              </HeaderToolTip>
            </motion.div>

            <motion.div
              style={{ cursor: "pointer" }}
              onHoverStart={() => {
                set_audioTip(true)
                toolTip_opacity.set(1)
              }}
              onHoverEnd={() => {
                set_audioTip(false)
                toolTip_opacity.set(0)
              }}
            >
              <HeaderIcon
                style={{
                  color: color_connected,
                  cursor: g.thera_clientUrl !== "noVideo" ? "pointer" : "",
                }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                whileHover={{
                  color: "#225ead",
                }}
                onClick={() => {
                  leftPlay()
                }}
              >
                <SiAudiomack />
              </HeaderIcon>
              <HeaderToolTip
                style={{
                  opacity: toolTip_opacity,
                  display: !showAudioTip ? "none" : "",
                  right: "15px",
                }}
              >
                Audio Test
              </HeaderToolTip>
            </motion.div>
          </Right>
        </Row>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const Row = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Right = styled.div`
  display: flex;
`

const HeaderIcon = styled(motion.div)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 2px;
`

const HeaderToolTip = styled(motion.div)`
  position: absolute;
  z-index: 10000;
  top: 45px;
  font-family: montserrat;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  background-color: #d1dce6;
  color: #245eab;
  padding: 5px 15px 5px 15px;
  margin-right: 10px;
  text-align: center;
  border-radius: 3px;
`

export default ShowClientVideo
